import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { getImage, getSrc, IGatsbyImageData } from "gatsby-plugin-image"
import cx from "classnames"

import { CustomPageProps } from "@utils/types"
import { BaseLayout } from "@layouts/BaseLayout"
import { Container } from "@ui/Container"
import { Row } from "@ui/Row"
import { PortfolioItem } from "@components/portfolio/PortfolioItem"
import { LinkToForm } from "@components/common/LinkToForm"
import TrianglesFirst from "@icons/PortfolioDesign1.svg"
import TrianglesSecond from "@icons/PortfolioDesign2.svg"

import * as s from "@styles/Portfolio.module.sass"

type ProjectType = {
  id: string
  photos: {
    directus_files_id: {
      imageFile: IGatsbyImageData
      id: string
    }
  }[]
  customer_logo?: {
    imageFile: IGatsbyImageData
  }
  translations: {
    title: string
    customer: string
    works: string
    description: string
  }[]
}

const PortfolioPage: React.FC<CustomPageProps> = ({
  data,
  pageContext,
  location,
}) => {
  // @ts-ignore
  const content = data.directus.items.portfolio
  // @ts-ignore
  const seo = data.directus.items.seo
  const seoContent = seo.translations[0]

  useEffect(() => {
    const body = document.querySelector("body")
    if (body) {
      body.classList.add("blue")
    }
    return () => {
      if (body) {
        body.classList.remove("blue")
      }
    }
  }, [])

  return (
    <BaseLayout
      theme="grayCorner"
      pageContext={pageContext}
      location={location}
      title={seoContent.title_portfolio || "Портфолио"}
      description={
        seoContent.description_portfolio ||
        "Специалисты компании ООО «Косей» помимо поставок промышленного оборудования предлагают своим клиентам комплексное решение задач в сегменте промышленного и коммерческого холодоснабжения: Сбор информации по предприятию для составления технического задания; Расчет и подбор основных и сопутствующие элементов системы, проектирование схемы внедрения оборудования на предприятии; Поставка на предприятие основного и дополнительного оборудования; Монтаж (шеф-монтаж) поставленного оборудования; Пуско-наладка, последующий гарантийный и пост-гарантийный сервис; Сервис и ремонт холодильного оборудования, как проданного нами, так и другого."
      }
      image={getSrc(seo.portfolio_image.imageFile)}
    >
      <section className={cx(s.root)}>
        <TrianglesFirst className={s.triangleFirst} />
        <TrianglesSecond className={s.triangleSecond} />
        <Container>
          <Row className={s.row}>
            <LinkToForm className={s.link} />
            <h1 className={s.header}>Портфоліо</h1>
            <div className={s.wrapper}>
              {content.map((project: ProjectType) => (
                <PortfolioItem
                  key={project.id}
                  className={s.item}
                  header={project.translations[0].title}
                  images={project.photos}
                  client={project.translations[0].customer}
                  clientLogo={
                    project.customer_logo
                      ? getImage(project.customer_logo.imageFile)
                      : undefined
                  }
                  works={project.translations[0].works}
                  description={project.translations[0].description}
                />
              ))}
            </div>
          </Row>
        </Container>
      </section>
    </BaseLayout>
  )
}

export const query = graphql`
  query PortfolioRuPage {
    directus {
      items {
        portfolio {
          sort
          id
          photos {
            directus_files_id {
              imageFile {
                childImageSharp {
                  gatsbyImageData(width: 1100, placeholder: BLURRED)
                }
              }
              id
            }
          }
          customer_logo {
            imageFile {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED)
              }
            }
            id
          }
          translations(filter: { languages_code: { code: { _eq: "ru-RU" } } }) {
            title
            customer
            works
            description
          }
        }
        seo {
          translations(filter: { languages_code: { code: { _eq: "ru-RU" } } }) {
            title_portfolio
            description_portfolio
          }
          portfolio_image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData(width: 1200, height: 627)
              }
            }
          }
        }
      }
    }
  }
`

export default PortfolioPage
